import React from "react"

import Layout from "../components/Layout"
import FullContainer from "../components/common/FullContainer"
import styled from "styled-components"

const BooksContainer = styled.div`
  margin-left: 4em;
  margin-right: 4em;

  @media (max-width: 600px) {
    margin-left: 1em;
    margin-right: 1em;

    h2 {
      font-size: 2px;
    }

    li {
      font-size: 16px;
    }
  }

  ul {
    list-style: none;
  }

  h2 {
    font-size: 24px;
  }

  a {
    cursor: pointer;
    color: blue;
  }
`

const Books = () => {
  return (
    <>
      <Layout>
        <FullContainer>
          <BooksContainer>
            <h2>
              These books have impacted both our professional and personal
              lives. Although they are not directly related to IT skills, they
              provide life-changing insights.
            </h2>
            <ul>
              <li>
                <a href="https://www.amazon.com/Extreme-Ownership-U-S-Navy-SEALs-ebook/dp/B0739PYQSS/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=1598895477&sr=1-3">
                  Extreme Ownership - Jocko Willink
                </a>
              </li>
              <li>
                <a href="https://www.amazon.com/4-Hour-Workweek-Escape-Live-Anywhere/dp/0307465357">
                  The 4-Hour Workweek - Tim Ferriss
                </a>
              </li>
              <li>
                <a href="https://www.amazon.com/Tools-Titans-Billionaires-World-Class-Performers/dp/1328683788">
                  Tools of Titans - Tim Ferriss
                </a>
              </li>
              <li>
                <a href="https://www.amazon.com/How-Will-Measure-Your-Life/dp/0062102419">
                  How Will You Measure Your Life - Clayton Christensen
                </a>
              </li>
            </ul>
          </BooksContainer>
        </FullContainer>
      </Layout>
    </>
  )
}

export default Books
